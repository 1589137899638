import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import CxSnippet from '../../../shared/components/CxSnippet';
import {
  COUNTRIES_WITH_REGIONS,
  DEFAULT_COUNTRY,
  MEETING_METHODS,
  QUESTION_TYPES,
  REQUIRED_VALUES,
} from '../../constants';
import { BookingContext } from '../../contexts/BookingContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import Item from '../../helpers/Item';
import DetailsFormValuesShape from '../../shapes/DetailsFormValuesShape';
import QuestionShape from '../../shapes/QuestionShape';
import Alert from '../Alert';
import Typography from '../Typography';
import CheckboxGroup from './CheckboxGroup';
import CheckboxInput from './CheckboxInput';
import PhoneInput from './PhoneInput';
import RadioGroup from './RadioGroup';
import Asterisk from './RequiredAsterisk';
import SelectInput from './SelectInput';
import TextareaInput from './TextareaInput';
import TextInput from './TextInput';
import UploadInput from './UploadInput';

const useStyles = createUseStyles({
  hidden: {
    display: 'none',
  },
  row: {
    '&:not(:last-child)': {
      marginBottom: '1.875rem',
    },
  },
  smsRequiredText: {
    display: 'block',
    paddingTop: '0.25rem',
  },
  alertContainer: {
    margin: '1rem 0',
  },
});

const AttendeeFields = ({
  additionalForm,
  attendeeAnswers,
  countries,
  country,
  errors,
  setValue,
  questions,
  regions,
  setFieldValue,
  setUploads,
  touched,
  values,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { clientFields, receiveSmsNotifications, callbackContactOptions } =
    useContext(SettingsContext);
  const [{ meetingMethod, uploads, bookingWalkIn }] =
    useContext(SelectionContext);
  const { smsError } = useContext(BookingContext);

  const phoneMeeting = meetingMethod === MEETING_METHODS.PHONE_CALL;

  const provinceFirstOption = () => {
    switch (values.country) {
      case 'CA':
        return intl.formatMessage({ id: 'DetailsForm.select_province' });
      case 'US':
        return intl.formatMessage({ id: 'DetailsForm.select_state' });
      default:
        return '';
    }
  };

  const setCheckboxValue = ({ target: { name, checked } }) =>
    setFieldValue(name, checked);

  const OptionMobilePhone = bookingWalkIn
    ? callbackContactOptions.mobile_phone === REQUIRED_VALUES.OPTIONAL
    : clientFields.mobile_phone === REQUIRED_VALUES.OPTIONAL;

  const RequireEmail = bookingWalkIn
    ? callbackContactOptions.email === REQUIRED_VALUES.REQUIRED
    : true;

  const showEmail =
    !bookingWalkIn ||
    (bookingWalkIn &&
      callbackContactOptions &&
      callbackContactOptions.email !== REQUIRED_VALUES.NA);

  const showMobile = bookingWalkIn
    ? callbackContactOptions &&
      callbackContactOptions.mobile_phone !== REQUIRED_VALUES.NA
    : clientFields && clientFields.mobile_phone !== REQUIRED_VALUES.NA;

  const showWorkPhone =
    !bookingWalkIn &&
    clientFields &&
    clientFields.work_phone !== REQUIRED_VALUES.NA;

  const showPhone =
    !bookingWalkIn && clientFields && clientFields.phone !== REQUIRED_VALUES.NA;

  const showSmsNotification = bookingWalkIn
    ? callbackContactOptions.sms_notifications === REQUIRED_VALUES.REQUIRED
    : clientFields.sms_notifications === REQUIRED_VALUES.REQUIRED;

  // Includes an asterisk if the field is required
  const formatLabel = ({ intlKey, label }, required = false) => {
    if (intlKey && typeof intlKey !== 'string') {
      return label;
    }

    if (label && typeof label !== 'string') {
      return label;
    }

    const localized = intlKey ? intl.formatMessage({ id: intlKey }) : label;

    return required ? (
      <>
        {localized}
        <Asterisk />
      </>
    ) : (
      localized
    );
  };

  return (
    <>
      <div className={classes.row}>
        <TextInput
          ariaRequired
          autocomplete="given-name"
          errors={errors.firstName && touched.firstName ? errors.firstName : []}
          id={additionalForm ? 'firstName-additional' : 'firstName'}
          label={formatLabel({ intlKey: 'DetailsForm.first_name' }, true)}
          mask
          name="firstName"
          onBlur={setValue}
          value={values.firstName}
        />
      </div>

      <div className={classes.row}>
        <TextInput
          ariaRequired
          autocomplete="family-name"
          errors={errors.lastName && touched.lastName ? errors.lastName : []}
          id={additionalForm ? 'lastName-additional' : 'lastName'}
          label={formatLabel({ intlKey: 'DetailsForm.last_name' }, true)}
          mask
          name="lastName"
          onBlur={setValue}
          value={values.lastName}
        />
      </div>

      {showEmail ? (
        <div className={classes.row}>
          <TextInput
            ariaRequired={RequireEmail}
            autocomplete="email"
            errors={errors.email && touched.email ? errors.email : []}
            id={additionalForm ? 'email-additional' : 'email'}
            label={formatLabel({ intlKey: 'DetailsForm.email' }, RequireEmail)}
            mask
            name="email"
            onBlur={setValue}
            type="email"
            value={values.email}
          />
          <CxSnippet targetId="email_disclosure" />
        </div>
      ) : null}

      {showMobile || phoneMeeting ? (
        <div className={classes.row}>
          <PhoneInput
            country={country}
            errors={
              errors.cellPhone && touched.cellPhone ? errors.cellPhone : []
            }
            id={additionalForm ? 'cellPhone-additional' : 'cellPhone'}
            label={formatLabel(
              { intlKey: 'DetailsForm.cell_phone' },
              !(phoneMeeting ? false : OptionMobilePhone),
            )}
            mask
            name="cellPhone"
            optional={phoneMeeting ? false : OptionMobilePhone}
            setFieldValue={setFieldValue}
            subtext={
              phoneMeeting ? (
                <FormattedMessage id="DetailsForm.cell_phone_context" />
              ) : null
            }
            value={values.cellPhone}
          />
          {receiveSmsNotifications ? (
            showSmsNotification ? (
              <div className={classes.smsRequiredText}>
                <Typography variant="help">
                  <CxSnippet
                    fallback={
                      <FormattedMessage id="DetailsForm.receive_sms_required" />
                    }
                    targetId="sms_required_disclosure"
                  />
                </Typography>
              </div>
            ) : (
              <CheckboxInput
                defaultChecked={values.receiveSms}
                error={Boolean(smsError)}
                id={additionalForm ? 'receiveSms-additional' : 'receiveSms'}
                label={
                  <CxSnippet
                    fallback={<FormattedMessage id="DetailsForm.receive_sms" />}
                    targetId="sms_opt_in_disclosure"
                  />
                }
                name="receiveSms"
                onChange={setCheckboxValue}
                value={values.receiveSms}
              />
            )
          ) : (
            <CxSnippet targetId="sms_not_sent_disclosure" />
          )}
          {smsError ? (
            <div className={classes.alertContainer}>
              <Alert
                message={<FormattedMessage id="DetailsForm.sms_error_title" />}
                secondary={
                  <span dangerouslySetInnerHTML={{ __html: smsError }} />
                }
                variant="error"
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {showPhone ? (
        <div className={classes.row}>
          <PhoneInput
            country={country}
            errors={
              errors.homePhone && touched.homePhone ? errors.homePhone : []
            }
            id={additionalForm ? 'homePhone-additional' : 'homePhone'}
            label={formatLabel(
              { intlKey: 'DetailsForm.home_phone' },
              clientFields.phone === REQUIRED_VALUES.REQUIRED,
            )}
            mask
            name="homePhone"
            optional={clientFields.phone === REQUIRED_VALUES.OPTIONAL}
            setFieldValue={setFieldValue}
            value={values.homePhone}
          />
        </div>
      ) : null}

      {showWorkPhone ? (
        <div className={classes.row}>
          <PhoneInput
            country={country}
            errors={
              errors.workPhone && touched.workPhone ? errors.workPhone : []
            }
            id={additionalForm ? 'workPhone-additional' : 'workPhone'}
            label={formatLabel(
              { intlKey: 'DetailsForm.work_phone' },
              clientFields.work_phone === REQUIRED_VALUES.REQUIRED,
            )}
            mask
            name="workPhone"
            optional={clientFields.work_phone === REQUIRED_VALUES.OPTIONAL}
            setFieldValue={setFieldValue}
            value={values.workPhone}
          />
        </div>
      ) : null}

      {clientFields &&
      clientFields.location_information !== REQUIRED_VALUES.NA ? (
        <>
          <div className={classes.row}>
            <TextInput
              // list of autocomplete is from W3C: https://www.w3.org/TR/WCAG21/#input-purposes
              ariaRequired={
                clientFields.location_information === REQUIRED_VALUES.REQUIRED
              }
              autocomplete="address-line1"
              errors={errors.address && touched.address ? errors.address : []}
              id={additionalForm ? 'address-additional' : 'address'}
              label={formatLabel(
                { intlKey: 'DetailsForm.address' },
                clientFields.location_information === REQUIRED_VALUES.REQUIRED,
              )}
              mask
              name="address"
              onBlur={setValue}
              value={values.address}
            />
          </div>

          <div className={classes.row}>
            <TextInput
              ariaRequired={
                clientFields.location_information === REQUIRED_VALUES.REQUIRED
              }
              autocomplete="address-level2"
              errors={errors.city && touched.city ? errors.city : []}
              id={additionalForm ? 'city-additional' : 'city'}
              label={formatLabel(
                { intlKey: 'DetailsForm.city' },
                clientFields.location_information === REQUIRED_VALUES.REQUIRED,
              )}
              mask
              name="city"
              onBlur={setValue}
              value={values.city}
            />
          </div>

          <div className={classes.row}>
            <TextInput
              ariaRequired={
                clientFields.location_information === REQUIRED_VALUES.REQUIRED
              }
              autocomplete="postal-code"
              errors={
                errors.postalCode && touched.postalCode ? errors.postalCode : []
              }
              id={additionalForm ? 'postalCode-additional' : 'postalCode'}
              label={formatLabel(
                { intlKey: 'DetailsForm.postal_code' },
                clientFields.location_information === REQUIRED_VALUES.REQUIRED,
              )}
              mask
              name="postalCode"
              onBlur={setValue}
              value={values.postalCode}
            />
          </div>

          <div className={classes.row}>
            <SelectInput
              ariaRequired={
                clientFields.location_information === REQUIRED_VALUES.REQUIRED
              }
              errors={errors.country && touched.country ? errors.country : []}
              firstOption={intl.formatMessage({
                id: 'DetailsForm.select_country',
              })}
              id={additionalForm ? 'country-additional' : 'country'}
              label={formatLabel(
                { intlKey: 'DetailsForm.country' },
                clientFields.location_information === REQUIRED_VALUES.REQUIRED,
              )}
              mask
              name="country"
              onBlur={setValue}
              options={countries}
              value={values.country}
            />
          </div>

          {COUNTRIES_WITH_REGIONS.includes(values.country) && (
            <div className={classes.row}>
              <SelectInput
                ariaRequired={
                  clientFields.location_information === REQUIRED_VALUES.REQUIRED
                }
                disabled={!regions.length}
                errors={
                  errors.province && touched.province ? errors.province : []
                }
                firstOption={provinceFirstOption()}
                id={additionalForm ? 'province-additional' : 'province'}
                label={formatLabel(
                  {
                    intlKey:
                      values.country === DEFAULT_COUNTRY
                        ? 'DetailsForm.state'
                        : 'DetailsForm.province',
                  },
                  clientFields.location_information ===
                    REQUIRED_VALUES.REQUIRED,
                )}
                mask
                name="province"
                onBlur={setValue}
                options={regions}
                value={values.province}
              />
            </div>
          )}
        </>
      ) : null}

      {questions.map((question) => {
        const key = `questions.${question.id}`;
        const error =
          Item.has(errors, key) &&
          errors[key] &&
          Item.has(touched, 'questions') &&
          question.type === QUESTION_TYPES.CHECKBOX
            ? Item.filter(touched.questions[question.id], Boolean).length > 0
            : Item.has(touched.questions, question.id) &&
              touched.questions[question.id];

        const props = {
          ariaRequired: question.required,
          errors: error ? errors[key] : [],
          helpText: question.placeholder,
          id: additionalForm
            ? `questions.${question.id}-additional`
            : `questions.${question.id}`,
          label: formatLabel({ label: question.label }, question.required),
          name: `questions.${question.id}`,
          onBlur: setValue,
          value: values.questions[question.id],
        };

        const defaultChecked = attendeeAnswers[question.id];

        return (
          <div className={classes.row} key={question.id}>
            {question.type === QUESTION_TYPES.TEXT && <TextInput {...props} />}

            {question.type === QUESTION_TYPES.SELECT && (
              <SelectInput {...props} options={question.options} />
            )}

            {question.type === QUESTION_TYPES.CHECKBOX && (
              <CheckboxGroup
                {...props}
                defaultChecked={defaultChecked}
                onChange={setCheckboxValue}
                options={question.options}
                setFieldValue={setFieldValue}
              />
            )}

            {question.type === QUESTION_TYPES.RADIO && (
              <RadioGroup
                {...props}
                defaultChecked={defaultChecked}
                onChange={setValue}
                options={question.options}
              />
            )}

            {question.type === QUESTION_TYPES.TEXTAREA && (
              <TextareaInput {...props} />
            )}

            {question.type === QUESTION_TYPES.UPLOAD && (
              <UploadInput
                {...props}
                setUploads={setUploads}
                setValue={setValue}
                uploads={uploads}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

AttendeeFields.propTypes = {
  additionalForm: PropTypes.bool,
  attendeeAnswers: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  country: PropTypes.string.isRequired,
  errors: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  ).isRequired,
  setValue: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(QuestionShape),
  regions: PropTypes.arrayOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setUploads: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
  ).isRequired,
  values: DetailsFormValuesShape.isRequired,
};

AttendeeFields.defaultProps = {
  additionalForm: false,
  attendeeAnswers: {},
  questions: [],
};

export default AttendeeFields;
