import { MEETING_METHOD_NAMES, PAGES } from '../constants';
import Api from './Api';

const TRANSACTION_STATUS_START = 'appointment scheduling start';
const TRANSACTION_STATUS_SUBMIT = 'appointment scheduling submit';
const EVENT_NAMES = {
  [PAGES.SERVICE]: 'appointment_start',
  [PAGES.TIMES]: 'appointment_times',
  [PAGES.VIEW_AVAILABLE_TIMES_AT_OTHER_LOCATIONS]: 'view_available_times_cta',
  [PAGES.DETAILS]: 'appointment_details',
  [PAGES.CONFIRMATION]: 'appointment_confirmation',
  [PAGES.RESCHEDULE]: 'appointment_reschedule',
  [PAGES.CANCEL]: 'appointment_cancel',
  [PAGES.CANCEL_CONFIRMATION]: 'appointment_cancel_confirmation',
  [PAGES.LOCATION]: 'appointment_location',
  [PAGES.CATEGORY]: 'appointment_category',
  [PAGES.MEETING_METHOD]: 'appointment_meeting_method',
  [PAGES.CHANGE_MEETING_METHOD]: 'appointment_change_meeting_method',
  [PAGES.MANAGE]: 'appointment_manage',
  [PAGES.SUMMARY]: 'appointment_summary',
  [PAGES.USER]: 'appointment_staff',
  [PAGES.USER_PREFERENCE]: 'appointment_staff_preference',
  [PAGES.USER_TIMES]: 'appointment_staff_times',
};

export default {
  view(page, trackingData = {}) {
    // Check if this is a duplicate page view (certain pages cause this to be called multiple times)
    if (page === window.previousIdentifier) {
      return;
    }

    window.previousIdentifier = page;

    Promise.resolve(trackingData).then(function (trackingData) {
      window.parent.postMessage({ trackingData: trackingData }, '*');

      // Tealium Tag Manager
      if (window.utag) {
        window.reportingData = trackingData;

        // This 'publisherFW' object is exposed via USBank's utag script implementation
        // They are making use of this rather than the generic Tealium utag.view() function
        if (window.publisherFW) {
          window.publisherFW.publishEvent('pageView', window.reportingData);
        } else {
          window.utag.view(trackingData);
        }
        return;
      }

      // Adobe Launch Tag Manager
      if (window.adobeTagManager) {
        window.adobeTagManager.push?.(trackingData);
        return;
      }

      // Google Tag Manager
      if (window.ga) {
        window.ga('send', 'pageview', { page });
        window.ga('clientTracker.send', 'pageview', { page });
        if (window.dataLayer) {
          window.dataLayer.push(trackingData);
        } else {
          window.dataLayer = [trackingData];
        }
        return;
      }
    });
  },

  event(action, category, label = null, value = undefined) {
    if (window.ga) {
      window.ga('send', 'event', category, action, label, value);
    }
  },

  canSubmitConversion() {
    return (
      window.google_trackConversion &&
      window.state.conversion.id &&
      window.state.conversion.label
    );
  },

  submitConversion() {
    window.google_trackConversion({
      google_conversion_id: window.state.conversion.id,
      google_conversion_label: window.state.conversion.label,
      google_conversion_format: '3',
      google_remarketing_only: false,
    });
  },

  getTrackingData(page, firstStep, selections) {
    const trackingData = {
      event: EVENT_NAMES[page],
      currentPage: page,
      siteSection: 'tools',
      subSiteSection: 'appointment scheduling',
      sitePlatform: 'dotcom',
      language: selections.locale
        ? selections.locale
        : document.documentElement.getAttribute('lang'),
      datasource: 'pageView',
      appointmentType: MEETING_METHOD_NAMES[selections.meetingMethod],
      appointmentCategory: selections.serviceCategory?.name,
      appointmentSubCategory: selections.service?.name,
      appointmentSubCategoryExternalId: selections.service?.externalId,
      referenceId: selections.confirmCode,
      location: selections.location?.name,
      locationExternalId: selections.location?.external_id,
    };

    if (!window.previousIdentifier) {
      trackingData.transactionStatus = TRANSACTION_STATUS_START;
    } else {
      switch (page) {
        case PAGES.CONFIRMATION:
          trackingData.transactionStatus = TRANSACTION_STATUS_SUBMIT;
          trackingData.appointmentId = selections.appointment;
          break;
        default:
          break;
      }
    }

    if (typeof window.transformReportingData === 'function') {
      return window.transformReportingData(selections, trackingData, Api);
    }

    return trackingData;
  },
};
