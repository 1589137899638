import type { ReactNode } from 'react';
import React from 'react';
import Typography from '../Typography';

const Asterisk: ReactNode = () => (
  <Typography component="span" variant="error">
    &nbsp;*
  </Typography>
);

export default Asterisk;
