import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import {
  COUNTRIES_WITH_REGIONS,
  DEFAULT_COUNTRY,
  REQUIRED_VALUES,
  PAGES,
} from '../../../constants';
import { SettingsContext } from '../../../contexts/SettingsContext';
import Api from '../../../helpers/Api';
import Forms from '../../../helpers/Forms.js';
import Ui from '../../../helpers/Ui';
import DetailsFormValuesShape from '../../../shapes/DetailsFormValuesShape';
import QuestionShape from '../../../shapes/QuestionShape';
import Button from '../../Button';
import ModalContent from '../../ModalContent';
import TrackPageView from '../../TrackPageView';
import AttendeeFields from '../AttendeeFields';
import DuplicateAttendeeAlert from '../mobile/DuplicateAttendeeAlert';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    width: '100%',
    maxHeight: 'calc(100vh)',
  },
  primary: {
    marginLeft: '0.5rem',
  },
});

const AdditionalDetailsForm = ({
  countries,
  country,
  errors,
  handleSubmit,
  isSubmitting,
  isValidating,
  questions,
  selectedAttendee,
  setFieldValue,
  setSelectedAttendee,
  setUploads,
  touched,
  validateForm,
  values,
}) => {
  const classes = useStyles();
  const { clientFields } = useContext(SettingsContext);

  const [regions, setRegions] = useState([]);

  const attendeeAnswers = selectedAttendee ? selectedAttendee.questions : {};
  const editing = selectedAttendee && selectedAttendee.email;

  const onSubmit = (event) => {
    event.preventDefault();

    Forms.syncAutofill(event.target, values);

    handleSubmit();
  };

  useEffect(() => {
    if (values.country === DEFAULT_COUNTRY && country !== values.country) {
      setFieldValue('country', country);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, setFieldValue]); // we don't want values.country dependency here

  useEffect(() => {
    if (
      clientFields &&
      clientFields.location_information !== REQUIRED_VALUES.NA &&
      values.country &&
      COUNTRIES_WITH_REGIONS.includes(values.country)
    ) {
      Api.regions()
        .find(values.country)
        .then(({ data }) => {
          const regionData = data.map(({ attributes: { code, name } }) => ({
            value: code,
            text: name,
          }));
          setRegions(regionData);
          if (!regionData.find(({ value }) => value === values.province)) {
            setFieldValue('province', '');
          }
        });
    } else {
      setRegions([]);
      setFieldValue('province', '');
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientFields, setFieldValue, values.country]); // we only want values.country dependency here

  useEffect(() => {
    Ui.scrollToError({
      errors,
      formId: 'additional-details-form',
      isSubmitting,
      isValidating,
    });
  }, [errors, isSubmitting, isValidating]);

  useEffect(() => {
    validateForm();
  }, [validateForm, values]);

  const setValue = ({ target: { name, value } }) => setFieldValue(name, value);

  return (
    <form
      className={classes.root}
      data-testid="additional-details-form"
      id="additional-details-form"
      onSubmit={onSubmit}
    >
      <TrackPageView
        identifier={editing ? PAGES.EDIT_ATTENDEE : PAGES.ADD_ATTENDEE}
      />
      <ModalContent
        buttons={
          <>
            <Button
              fullWidth={false}
              onClick={() => setSelectedAttendee(null)}
              type="button"
              variant="secondary"
            >
              <FormattedMessage id="Ui.cancel" />
            </Button>
            <Button
              classes={{ override: classes.primary }}
              data-testid="add-attendee-button"
              fullWidth={false}
              type="submit"
            >
              <FormattedMessage
                id={
                  selectedAttendee && selectedAttendee.email
                    ? 'DetailsForm.edit'
                    : 'DetailsForm.add'
                }
              />
            </Button>
          </>
        }
        header={
          <FormattedMessage
            id={
              editing
                ? 'DetailsForm.edit_attendee_title'
                : 'DetailsForm.add_attendee_title'
            }
          />
        }
      >
        <div className="min-w-120">
          {errors.duplicate ? <DuplicateAttendeeAlert /> : null}
          <AttendeeFields
            additionalForm
            attendeeAnswers={attendeeAnswers}
            countries={countries}
            country={country}
            errors={errors}
            questions={questions}
            regions={regions}
            setFieldValue={setFieldValue}
            setUploads={setUploads}
            setValue={setValue}
            touched={touched}
            values={values}
          />
        </div>
      </ModalContent>
    </form>
  );
};

AdditionalDetailsForm.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  country: PropTypes.string.isRequired,
  errors: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValidating: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(QuestionShape),
  selectedAttendee: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      ),
      PropTypes.number,
      PropTypes.string,
    ]),
  ),
  setFieldValue: PropTypes.func.isRequired,
  setSelectedAttendee: PropTypes.func.isRequired,
  setUploads: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
  ).isRequired,
  validateForm: PropTypes.func.isRequired,
  values: DetailsFormValuesShape.isRequired,
};

AdditionalDetailsForm.defaultProps = {
  questions: [],
  selectedAttendee: null,
};

export default AdditionalDetailsForm;
