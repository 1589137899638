import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import Item from '../helpers/Item';
import Url from '../helpers/Url';

const StepAbilityContext = createContext([{}, () => {}]);

const StepAbilityProvider = ({ children, previous, steps }) => {
  const history = useHistory();
  const { shouldUseKioskEnhancements } = useContext(FeatureDecisionContext);

  const previousStep = steps.find(
    (step) => step.id === Item.get(previous, 'id'),
  );

  let canStepBackwards = !Item.get(previousStep, 'locked', false);

  const useNewKiosk = Url.params(history.location.search)?.use_new_kiosk;
  const isWalkIn = Url.params(history.location.search)?.walk_in;
  if (shouldUseKioskEnhancements && useNewKiosk && isWalkIn && !previousStep) {
    canStepBackwards = !!previousStep;
  }

  const abilities = {
    can: {
      step: {
        backwards: canStepBackwards,
      },
    },
  };

  return (
    <StepAbilityContext.Provider value={abilities}>
      {children}
    </StepAbilityContext.Provider>
  );
};

StepAbilityProvider.propTypes = {
  children: PropTypes.element.isRequired,
  previous: PropTypes.shape({
    id: PropTypes.string,
  }),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      locked: PropTypes.bool,
    }),
  ).isRequired,
};

StepAbilityProvider.defaultProps = {
  previous: undefined,
};

export { StepAbilityContext, StepAbilityProvider };
