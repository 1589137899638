import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Item from '../../helpers/Item';
import InputOptionShape from '../../shapes/InputOptionShape';
import Typography from '../Typography';
import CheckboxInput from './CheckboxInput';
import HelpText from './HelpText';
import InputErrors from './InputErrors';

const useStyles = createUseStyles({
  label: {
    cursor: 'pointer',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  },
});

const shouldBeChecked = (answers, index) => {
  if (!Item.length(answers)) {
    return false;
  }

  const answer = answers[index];

  return Boolean(answer);
};

const CheckboxGroup = ({
  ariaRequired,
  defaultChecked,
  errors,
  helpText,
  id,
  label,
  name,
  onChange,
  options,
}) => {
  const classes = useStyles();

  return (
    <fieldset aria-required={ariaRequired}>
      <legend>
        <Typography classes={{ root: classes.label }} variant="label">
          {label}
        </Typography>
        <span className={classes.visuallyHidden}>{errors}</span>
      </legend>
      {options.map((option, index) => (
        <CheckboxInput
          defaultChecked={shouldBeChecked(defaultChecked, index)}
          id={`${id}[${index}]` || name}
          key={`${option.text}-${option.value}`}
          label={option.text}
          name={`${name}[${option.value}]`}
          onChange={onChange}
          value={option.value}
        />
      ))}
      {errors.length > 0 ? (
        <InputErrors errors={errors} />
      ) : (
        <HelpText value={helpText} />
      )}
    </fieldset>
  );
};

CheckboxGroup.propTypes = {
  ariaRequired: PropTypes.bool,
  defaultChecked: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  errors: PropTypes.arrayOf(PropTypes.string),
  helpText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(InputOptionShape),
};

CheckboxGroup.defaultProps = {
  ariaRequired: false,
  defaultChecked: [],
  errors: [],
  helpText: null,
  id: '',
  options: [],
};

export default CheckboxGroup;
