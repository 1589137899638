import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { default as ReCAPTCHA } from 'react-google-recaptcha-enterprise';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { useFeatureDecisions } from '../../../shared/contexts/FeatureDecisionContext';
import mode from '../../../shared/helpers/Mode';
import {
  COUNTRIES_WITH_REGIONS,
  DEFAULT_COUNTRY,
  REQUIRED_VALUES,
} from '../../constants';
import { BookingContext } from '../../contexts/BookingContext';
import { FeatureContext } from '../../contexts/FeatureContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import { MOBILE, ViewModeContext } from '../../contexts/ViewModeContext';
import Api from '../../helpers/Api';
import Forms from '../../helpers/Forms';
import Limits, { UNLIMITED } from '../../helpers/Limits';
import Ui from '../../helpers/Ui';
import DetailsFormValuesShape from '../../shapes/DetailsFormValuesShape';
import QuestionShape from '../../shapes/QuestionShape';
import AttendeeList from '../AttendeeList';
import Button from '../Button';
import Policies from '../Policies';
import Typography from '../Typography';
import AttendeeFields from './AttendeeFields';
import CheckboxInput from './CheckboxInput';
import DuplicateAttendeeAlert from './mobile/DuplicateAttendeeAlert';
import Asterisk from './RequiredAsterisk';
import TextareaInput from './TextareaInput';

const useStyles = createUseStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  attendeesLimit: {
    marginLeft: '0.25rem',
  },
  compliance: {
    marginTop: '1.5rem',
    '& > label': {
      paddingTop: 0,
    },
  },
  row: {
    '&:not(:last-child)': {
      marginBottom: '1.875rem',
    },
  },
  multipleAttendees: {
    margin: '3.75rem 0',
  },
  policies: {
    marginBottom: '1.5rem',
  },
  section: {
    background: theme.palette.white,
    padding: '0 1.25rem 1.25rem',
    '&:last-child': {
      paddingBottom: '1.25rem',
    },
  },
  submitDesktop: {
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    marginTop: 'auto',
    paddingTop: '1.25rem',
  },
  sectionMobile: {
    borderTop: 0,
    marginTop: '0.25rem',
    padding: '1.25rem',
  },
  title: {
    marginBottom: '1.25rem',
  },
  recaptchaMobile: {
    marginTop: '3.75rem',
  },
}));

const DetailsForm = ({
  attendeeAnswers,
  countries,
  country,
  errors,
  handleSubmit,
  isSubmitting,
  isValidating,
  questions,
  setFieldValue,
  setSelectedAttendee,
  setUploads,
  touched,
  validateForm,
  values,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });
  const { shouldUseJoinTheLineFromCV } = useFeatureDecisions();

  const {
    allowMultipleAttendees,
    copyMultipleAttendeesAnswers: copyAttendeesAnswers,
    explicitComplianceAgreement,
    multipleAttendeesLimit: attendeesLimit,
    clientFields,
    callbackContactOptions,
  } = useContext(SettingsContext);
  const layout = useContext(ViewModeContext);
  const { clientGoogleRecaptcha } = useContext(FeatureContext);
  const recaptchaRef = useRef(null);
  const recaptchaSiteKey = window.state.google_recaptcha_site_key;
  const { submitting } = useContext(BookingContext);
  const [{ attendees, shortcuts, service, bookingWalkIn }] =
    useContext(SelectionContext);

  const [regions, setRegions] = useState([]);

  const requiresExplicitConsent = mode.isKiosk()
    ? false
    : explicitComplianceAgreement;
  const [bookable, setBookable] = useState(requiresExplicitConsent ? 0 : 1);

  const limit = Limits.get();
  const attendeeLimit = service && service.group ? limit - 1 : attendeesLimit;
  const showAttendeeLimit =
    service && service.group ? limit !== UNLIMITED : attendeesLimit;

  const formRef = useRef(null);

  useEffect(() => {
    if (values.country === DEFAULT_COUNTRY && country !== values.country) {
      setFieldValue('country', country);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, setFieldValue]); // we don't want values.country dependency here

  useEffect(() => {
    if (clientFields?.notes !== 'na' && shortcuts?.notes && !values.notes) {
      setFieldValue('notes', shortcuts?.notes);
    }
  }, [clientFields?.notes, setFieldValue, shortcuts?.notes, values]);

  useEffect(() => {
    if (
      clientFields &&
      clientFields.location_information !== REQUIRED_VALUES.NA &&
      values.country &&
      COUNTRIES_WITH_REGIONS.includes(values.country)
    ) {
      Api.regions()
        .find(values.country)
        .then(({ data }) => {
          const regionData = data.map(({ attributes: { code, name } }) => ({
            value: code,
            text: name,
          }));
          setRegions(regionData);
          if (!regionData.find(({ value }) => value === values.province)) {
            setFieldValue('province', '');
          }
        });
    } else {
      setRegions([]);
      setFieldValue('province', '');
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientFields, setFieldValue, values.country]); // we only need values.country dependency here

  useEffect(() => {
    Ui.scrollToError({
      errors,
      formId: 'details-form',
      isSubmitting,
      isValidating,
    });
  }, [errors, isSubmitting, isValidating]);

  useEffect(() => {
    validateForm();
  }, [validateForm, values]);

  const addNewAttendee = () => {
    Forms.syncAutofill(formRef.current, values);

    if (copyAttendeesAnswers) {
      const answers = JSON.parse(JSON.stringify(values.questions));

      setSelectedAttendee({ questions: answers });

      return;
    }

    setSelectedAttendee({});
  };

  const agreeToTerms = (e) => setBookable(e.currentTarget.checked ? 1 : 0);

  const setValue = ({ target: { name, value } }) => setFieldValue(name, value);

  const onSubmitWithReCAPTCHA = async (event) => {
    event.preventDefault();
    if (clientGoogleRecaptcha && recaptchaSiteKey) {
      const token = await recaptchaRef.current.executeAsync();
      setFieldValue('recaptchaToken', token);
    }

    Forms.syncAutofill(event.target, values);

    handleSubmit();
  };

  const showNotes = bookingWalkIn
    ? callbackContactOptions?.notes !== REQUIRED_VALUES.NA
    : clientFields?.notes !== REQUIRED_VALUES.NA;

  const requiredNotes = bookingWalkIn
    ? callbackContactOptions?.notes === REQUIRED_VALUES.REQUIRED
    : clientFields?.notes === REQUIRED_VALUES.REQUIRED;

  return (
    <form
      className={classes.root}
      data-testid="details-form"
      id="details-form"
      onSubmit={onSubmitWithReCAPTCHA}
      ref={formRef}
    >
      <section
        className={classNames(
          classes.section,
          layout === MOBILE && classes.sectionMobile,
        )}
      >
        {errors.duplicate ? <DuplicateAttendeeAlert /> : null}
        <AttendeeFields
          attendeeAnswers={attendeeAnswers}
          countries={countries}
          country={country}
          errors={errors}
          questions={questions}
          regions={regions}
          setFieldValue={setFieldValue}
          setUploads={setUploads}
          setValue={setValue}
          touched={touched}
          values={values}
        />

        {showNotes ? (
          <div className={classes.row}>
            <TextareaInput
              ariaRequired={requiredNotes}
              errors={errors.notes && touched.notes ? errors.notes : []}
              label={
                <>
                  <FormattedMessage id="DetailsForm.notes" />
                  {requiredNotes ? <Asterisk /> : null}
                </>
              }
              mask
              name="notes"
              onBlur={setValue}
              value={shortcuts?.notes || values.notes}
            />
          </div>
        ) : null}
      </section>

      {allowMultipleAttendees ? (
        <section
          className={classNames(
            classes.section,
            layout === MOBILE && classes.sectionMobile,
          )}
        >
          <div
            className={classNames(
              layout !== MOBILE && classes.multipleAttendees,
            )}
          >
            <h3>
              <Typography classes={{ root: classes.title }} variant="h6">
                <FormattedMessage id="DetailsForm.additional_attendees" />
              </Typography>
              {showAttendeeLimit ? (
                <Typography
                  classes={{ root: classes.attendeesLimit }}
                  variant="body2"
                >
                  <FormattedMessage
                    id="DetailsForm.additional_attendees_limit"
                    values={{ limit: attendeeLimit }}
                  />
                </Typography>
              ) : null}
            </h3>
            <AttendeeList setSelectedAttendee={setSelectedAttendee} />
            <Button
              data-testid="054a25b9"
              disabled={
                attendeeLimit ? attendees.length >= attendeeLimit : null
              }
              onClick={addNewAttendee}
              variant="outlined"
            >
              <FormattedMessage id="DetailsForm.add_attendee" />
            </Button>
          </div>
        </section>
      ) : null}

      <section
        className={classNames(
          classes.section,
          layout === MOBILE ? classes.sectionMobile : classes.submitDesktop,
        )}
      >
        <div className={classes.policies}>
          <Policies />
          {requiresExplicitConsent ? (
            <div className={classes.compliance}>
              <CheckboxInput
                bold
                label={
                  <FormattedMessage id="DetailsForm.explicit_compliance_agreement_label" />
                }
                name="explicit_compliance_agreement"
                onChange={agreeToTerms}
                value={bookable === 1 ? 0 : 1}
              />
            </div>
          ) : null}
        </div>
        <Button
          aria-label={intl.formatMessage({ id: 'Summary.book_appointment' })}
          disabled={!bookable || submitting}
          isLoading={submitting}
          type="submit"
        >
          <FormattedMessage
            id={
              bookingWalkIn && shouldUseJoinTheLineFromCV
                ? 'Summary.join_the_line'
                : 'Summary.book_appointment'
            }
          />
        </Button>
      </section>
      {clientGoogleRecaptcha && recaptchaSiteKey ? (
        <section>
          <input name="recaptchaToken" type="hidden" />
          <ReCAPTCHA
            className={classNames(
              layout === MOBILE ? classes.recaptchaMobile : null,
            )}
            ref={recaptchaRef}
            sitekey={recaptchaSiteKey}
            size="invisible"
          />
        </section>
      ) : null}
    </form>
  );
};

DetailsForm.propTypes = {
  attendeeAnswers: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  country: PropTypes.string.isRequired,
  errors: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValidating: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(QuestionShape),
  setFieldValue: PropTypes.func.isRequired,
  setSelectedAttendee: PropTypes.func.isRequired,
  setUploads: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
  ).isRequired,
  validateForm: PropTypes.func.isRequired,
  values: DetailsFormValuesShape.isRequired,
};

DetailsForm.defaultProps = {
  attendeeAnswers: {},
  questions: [],
};

export default DetailsForm;
