import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import { MEETING_METHODS, SHORTCUTS, STEPS } from '../constants';
import {
  filterMethods,
  shouldSkipLocationStep,
} from '../helpers/MeetingMethods';
import Shortcuts from '../helpers/Shortcuts';
import CheckIn from '../steps/CheckIn';
import DateTime from '../steps/DateTime';
import Details from '../steps/Details';
import Location from '../steps/Location';
import MeetingMethods from '../steps/MeetingMethods';
import Service from '../steps/Service';
import { SelectionContext } from './SelectionContext';
import { SettingsContext } from './SettingsContext';
import { TimezoneContext } from './TimezoneContext';

let hasSetTimezone = false;

const initSteps = [
  {
    id: STEPS.SERVICE,
    component: Service,
  },
  {
    id: STEPS.LOCATION,
    component: Location,
  },
  {
    id: STEPS.MEETING_METHODS,
    component: MeetingMethods,
  },
  {
    id: STEPS.TIMES,
    component: DateTime,
  },
  {
    id: STEPS.DETAILS,
    component: Details,
  },
  {
    id: STEPS.CHECK_IN,
    component: CheckIn,
  },
];

const StepContext = createContext([]);

const StepProvider = ({ children }) => {
  const history = useHistory();
  const { firstStep, includeLocationStep } = useContext(SettingsContext);
  const [{ location, service, user }, setSelections] =
    useContext(SelectionContext);
  const [, setTimezone] = useContext(TimezoneContext);
  const { shouldUseKioskEnhancements } = useContext(FeatureDecisionContext);
  const useNewKiosk = new URLSearchParams(history.location.search).has(
    'use_new_kiosk',
  );
  const isWalkIn = new URLSearchParams(history.location.search).has('walk_in');

  const order = window.state.steps;
  const sorter = (a, b) => order.indexOf(a.id) - order.indexOf(b.id);
  const filterer = (step) => order.findIndex((item) => item === step.id) >= 0;
  const mapper = (step) => {
    step.locked = false;

    if (step.id === STEPS.LOCATION && location && location.locked) {
      step.locked = true;
    }

    if (step.id === STEPS.LOCATION && Shortcuts.exists(SHORTCUTS.LOCATION)) {
      step.locked = true;

      if (
        !hasSetTimezone &&
        location &&
        location.timezone &&
        location.physical
      ) {
        setTimezone(location.timezone);
        hasSetTimezone = true;
      }
    }

    if (step.id === STEPS.MEETING_METHODS) {
      if (
        !hasSetTimezone &&
        location &&
        location.timezone &&
        location.physical
      ) {
        setTimezone(location.timezone);
        hasSetTimezone = true;
      }

      let method = Shortcuts.get(SHORTCUTS.MEETING_METHOD);

      if (!method) {
        const methods = filterMethods(service, user);

        method =
          methods.length === 1 && methods[0].id === MEETING_METHODS.AT_LOCATION
            ? MEETING_METHODS.AT_LOCATION
            : method;
      }

      if (method) {
        const skipLocation = shouldSkipLocationStep(
          method,
          service,
          firstStep,
          includeLocationStep,
        );

        step.locked = skipLocation || Shortcuts.exists(SHORTCUTS.LOCATION);
      } else {
        step.locked = false;
      }
    }

    if (step.id === STEPS.SERVICE && Shortcuts.exists(SHORTCUTS.SERVICE)) {
      step.locked = true;
    }

    if (step.id === STEPS.TIMES && Shortcuts.exists(SHORTCUTS.TIMESLOT)) {
      step.locked = true;
    }

    return step;
  };
  const locker = (a, b) =>
    a.locked === b.locked ? 0 : a.locked === true ? -1 : 1;

  const [steps, setSteps] = useState(() =>
    initSteps.filter(filterer).sort(sorter).map(mapper).sort(locker),
  );

  const lockStep = (id) => {
    setSteps(
      steps.map((step) => (step.id === id ? { ...step, locked: true } : step)),
    );
  };
  const remapSteps = () => {
    setSteps(steps.map(mapper).sort(locker));
  };

  const resetInitialSteps = () => {
    setSteps(initSteps.filter(filterer).sort(sorter).map(mapper).sort(locker));
  };

  useEffect(() => {
    if (isWalkIn) {
      setSelections({ bookingWalkIn: true });
    }
  }, [isWalkIn, setSelections]);

  useEffect(() => {
    if (shouldUseKioskEnhancements && useNewKiosk && isWalkIn) {
      setSteps(
        initSteps.filter((step) =>
          [STEPS.SERVICE, STEPS.DETAILS].includes(step.id),
        ),
      );
    }
    // not all dependencies are memoized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUseKioskEnhancements, useNewKiosk, isWalkIn]);

  return (
    <StepContext.Provider
      value={[steps, lockStep, remapSteps, resetInitialSteps]}
    >
      {children}
    </StepContext.Provider>
  );
};

StepProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { StepContext, StepProvider };
