import { Progress } from '@coconut-software/ui';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Dates from '../../../shared/helpers/Dates';
import { MEETING_METHOD_TITLES, MEETING_METHODS, PAGES } from '../../constants';
import { SelectionContext } from '../../contexts/SelectionContext';
import { DESKTOP, ViewModeContext } from '../../contexts/ViewModeContext';
import Item from '../../helpers/Item';
import Button from '../Button';
import ModalContent from '../ModalContent';
import TrackPageView from '../TrackPageView';
import Typography from '../Typography';
import SelectInput from './SelectInput';

const messages = {
  [MEETING_METHODS.AT_LOCATION]: 'MeetingMethodForm.message.in_person',
  [MEETING_METHODS.PHONE_CALL]: 'MeetingMethodForm.message.phone_call',
  [MEETING_METHODS.VIDEO_CALL]: 'MeetingMethodForm.message.video_call',
  [MEETING_METHODS.OFF_SITE]: 'MeetingMethodForm.message.off_site',
};

const useStyles = createUseStyles((theme) => ({
  row: {
    '&:not(:last-child)': {
      marginBottom: '1.875rem',
    },
  },
  secondary: {
    marginRight: '0.5rem',
  },
  section: {
    marginBottom: '1.25rem',
  },
  time: {
    fontWeight: theme.fontWeights.medium,
  },
}));

const MeetingMethodForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  loading,
  meetingMethods,
  toggleModal,
  values,
}) => {
  const intl = useIntl();
  const mode = useContext(ViewModeContext);
  const classes = useStyles({ theme: useTheme() });
  const [{ date, meetingMethod }] = useContext(SelectionContext);
  const [{ disabled, noMeetingOptions }, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      disabled: true,
      noMeetingOptions: false,
    },
  );

  const options = meetingMethods.map((meetingMethod) => ({
    text: intl.formatMessage({
      id: MEETING_METHOD_TITLES[meetingMethod].primary,
    }),
    value: meetingMethod,
  }));

  useEffect(() => {
    if (!loading) {
      if (meetingMethods.length === 0) {
        setState({ noMeetingOptions: true, disabled: true });
      } else {
        setState({ disabled: false, noMeetingOptions: false });
      }
    }
  }, [loading, meetingMethods]);

  return (
    <form onSubmit={handleSubmit}>
      <TrackPageView identifier={PAGES.CHANGE_MEETING_METHOD} />
      <ModalContent
        buttons={
          <>
            <Button
              classes={mode === DESKTOP ? { override: classes.secondary } : ''}
              fullWidth={false}
              onClick={toggleModal}
              type="button"
              variant="tertiary"
            >
              <FormattedMessage id="MeetingMethodForm.keep" />
            </Button>
            <Button
              disabled={noMeetingOptions || loading}
              fullWidth={false}
              type="submit"
            >
              <FormattedMessage id="MeetingMethodForm.update_method" />
            </Button>
          </>
        }
        header={<FormattedMessage id="MeetingMethodForm.title" />}
      >
        <section className={classes.section}>
          <Typography variant="regular">
            <FormattedMessage
              id={messages[meetingMethod]}
              values={{
                date: (
                  <span className={classes.time}>
                    <FormattedMessage
                      id="MeetingMethodForm.appointment_date"
                      values={{
                        date: Dates.toWeekdayDateMonthYear(date),
                        time: Dates.time(date, true),
                      }}
                    />
                  </span>
                ),
              }}
            />
          </Typography>
        </section>

        <div className={classes.row}>
          <SelectInput
            disabled={disabled}
            errors={
              Item.has(errors, 'meetingMethod') ? errors.meetingMethod : []
            }
            firstOption={
              noMeetingOptions
                ? intl.formatMessage({
                    id: MEETING_METHOD_TITLES[meetingMethod].primary,
                  })
                : null
            }
            label={<FormattedMessage id="MeetingMethodForm.new_method_label" />}
            name="meetingMethod"
            onBlur={handleBlur}
            onChange={handleChange}
            options={options}
            value={values.meetingMethod}
          />
          {loading ? (
            <div className="mt-4 flex items-center">
              <Progress size="small" variant="circular" />
              <div className="text-gray-600 ml-3 text-15">
                <FormattedMessage id="ChangesToYourAppointment.loading" />
              </div>
            </div>
          ) : null}
          {noMeetingOptions && !loading ? (
            <div className="flex items-center mt-4 text-gray-600 text-15">
              <FormattedMessage id="ChangesToYourAppointment.no_meeting_method_options" />
            </div>
          ) : null}
        </div>
      </ModalContent>
    </form>
  );
};

MeetingMethodForm.propTypes = {
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.func.isRequired,
  meetingMethods: PropTypes.arrayOf(PropTypes.number).isRequired,
  toggleModal: PropTypes.func.isRequired,
  values: PropTypes.shape({}),
};

export default MeetingMethodForm;
