import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import CxSnippet from '../../shared/components/CxSnippet';
import { visibilities } from '../../shared/constants';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import { useVendorColors } from '../../shared/contexts/VendorColorContext';
import Detective from '../../shared/helpers/Detective';
import mode from '../../shared/helpers/Mode';
import UTM from '../../shared/helpers/UtmParams';
import Alert from '../components/Alert';
import CircularProgress from '../components/CircularProgress';
import Card from '../components/desktop/Card';
import Account from '../components/icons/Account';
import Done from '../components/icons/Done';
import Event from '../components/icons/Event';
import KioskDone from '../components/icons/KioskDone';
import KioskEvent from '../components/icons/KioskEvent';
import KioskSearch from '../components/icons/KioskSearch';
import LocationPin from '../components/icons/LocationPin';
import Search from '../components/icons/Search';
import Landing from '../components/Landing';
import LandingKiosk from '../components/LandingKiosk';
import List from '../components/List';
import TrackPageView from '../components/TrackPageView';
import Typography from '../components/Typography';
import { MEETING_METHODS, PAGES } from '../constants';
import { FeatureContext } from '../contexts/FeatureContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { StepContext } from '../contexts/StepContext';
import { ViewModeContext, MOBILE } from '../contexts/ViewModeContext';
import Api from '../helpers/Api';
import Resources from '../helpers/Resources';
import Url from '../helpers/Url';

const MAX_BTN_PERCENTAGE = 48;
const REMAINDER_PERCENTAGE = 100 - MAX_BTN_PERCENTAGE * 2;

const useStyles = createUseStyles((theme) => ({
  adornment: {
    alignItems: 'center',
    background: theme.palette.primary[400],
    borderRadius: theme.borderRadius.full,
    display: 'flex',
    justifyContent: 'center',
    minHeight: '2.5rem',
    minWidth: '2.5rem',
  },
  address: {
    marginTop: '0.125rem',
  },
  icon: {
    color: theme.palette.primary[400],
    marginRight: '0.75rem',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100%',
  },
  row: {
    marginTop: '1.5rem',
  },
  kioskWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  kioskTitle: {
    fontSize: '3.5rem',
    fontWeight: 700,
    lineHeight: '3.5rem',
    color: ({ inverted }) =>
      inverted ? theme.palette.black : theme.palette.white,
    marginBottom: '2.5rem',
  },
  kioskSubtitle: {
    opacity: '60%',
  },
  kioskDivider: {
    borderTop: ({ inverted }) =>
      inverted
        ? '1px solid rgba(0, 0, 0, 0.6)'
        : '1px solid rgba(255, 255, 255, 0.6)',
    margin: `${REMAINDER_PERCENTAGE}% 0`,
  },
  kioskList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  kioskListItem: {
    textAlign: 'left',
    flexGrow: 1,
    background: 'transparent',
    border: ({ inverted }) =>
      `3px solid ${inverted ? theme.palette.black : theme.palette.white}`,
    borderRadius: theme.borderRadius.lg,
    padding: '2rem',
    width: '100%',
    marginBottom: `${REMAINDER_PERCENTAGE}%`,
  },
  buttonPrimary: {
    background: theme.palette.white,
  },
  buttonTitle: {
    color: ({ inverted }) =>
      inverted ? theme.palette.black : theme.palette.white,
    fontSize: theme.textSizes.xl,
    marginBottom: '.5rem',
  },
  buttonTitlePrimary: {
    color: ({ inverted }) =>
      inverted ? theme.palette.black : theme.palette.primary[400],
  },
  buttonSubtitle: {
    color: ({ inverted }) =>
      inverted ? theme.palette.black : theme.palette.white,
    fontSize: theme.textSizes.md,
    lineHeight: '1rem',
  },
  buttonSubtitlePrimary: {
    color: ({ inverted }) =>
      inverted ? theme.palette.black : theme.palette.primary[400],
  },
  kioskListItemHalf: {
    maxWidth: `${MAX_BTN_PERCENTAGE}%`,
    marginBottom: '2%',
  },
  kioskButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  kioskIcon: {
    color: ({ inverted }) =>
      inverted ? theme.palette.black : theme.palette.white,
    paddingRight: '2rem',
    '& > svg': {
      width: '2.5rem',
      height: '2.5rem',
    },
  },
  kioskIconPrimary: {
    '& > svg > path': {
      fill: ({ inverted }) =>
        inverted ? theme.palette.black : theme.palette.primary[400],
    },
  },
  privateLocationButton: {
    marginTop: '1rem',
  },
}));

const getOptions = ({
  history,
  location,
  queueable,
  remapSteps,
  search,
  service,
  isTouchKiosk,
  featureDecisions,
  setSelections,
  showAppointmentOption,
}) => {
  const params = Url.params(history.location.search);
  const utmParams = UTM.getUtmParams(params, '&');

  let originParams = '';
  if (mode.isKiosk()) {
    originParams = `&${mode.kioskParam()}`;
  } else if (mode.isTv()) {
    originParams = `&${mode.tvParam()}`;
  }

  const isEnhancedKioskMode =
    featureDecisions.shouldUseKioskEnhancements &&
    params.use_new_kiosk &&
    mode.isKiosk();

  const bookingThroughParam = mode.getBookedThroughKioskQRParam();
  const serviceParam = service ? `&service=${service}` : '';
  const workflowParams = params.workflow ? `&workflow=${params.workflow}` : '';
  let checkInUrl = `/check-in/${location.id}${search}${utmParams}${originParams}${workflowParams}`;

  if (isEnhancedKioskMode) {
    checkInUrl += `&use_new_kiosk${bookingThroughParam}`;
  }

  const options = [
    ...(!mode.isInvitation()
      ? [
          {
            id: 'check_in',
            icon: isTouchKiosk ? <KioskDone /> : <Done />,
            action: () => {
              featureDecisions.shouldUseCheckInOnCV
                ? history.push(checkInUrl)
                : window.location.assign(checkInUrl);
            },
            primary: 'LandingChoices.check_in_title',
            secondary: 'LandingChoices.check_in_subtitle',
            isPrimaryAction: false,
          },
        ]
      : []),
    ...(queueable
      ? [
          {
            id: 'callback',
            icon: <Account />,
            action: () => {
              if (isEnhancedKioskMode) {
                setSelections({ bookingWalkIn: true });
                history.push({
                  pathname: '/',
                  search: `${window.location.search}&walk_in`,
                });
              } else {
                window.location.assign(
                  `/callback-service/${location.id}${search}${serviceParam}${utmParams}${originParams}${workflowParams}`,
                );
              }
            },
            primary: 'LandingChoices.callback_title',
            secondary: 'LandingChoices.callback_subtitle',
            isPrimaryAction: true,
          },
        ]
      : []),
    ...(showAppointmentOption
      ? [
          {
            id: 'book_appointment',
            icon: isTouchKiosk ? <KioskEvent /> : <Event />,
            action: () => {
              window.state.shortcuts.location =
                Resources.unformatLocation(location);
              remapSteps();

              if (isEnhancedKioskMode) {
                history.push({
                  pathname: '/',
                  search: `?location=${location.id}${serviceParam}${utmParams}${originParams}${workflowParams}&use_new_kiosk${bookingThroughParam}`,
                });
              } else {
                history.push({
                  pathname: '/',
                  search: `?location=${location.id}${serviceParam}${utmParams}${originParams}${workflowParams}`,
                });
              }
            },
            primary: 'LandingChoices.book_appointment_title',
            secondary: 'LandingChoices.book_appointment_subtitle',
            isPrimaryAction: false,
          },
        ]
      : []),
  ];

  if (isTouchKiosk) {
    const primary = [];
    const others = [];

    // eslint-disable-next-line no-confusing-arrow
    options.map((o) => (o.isPrimaryAction ? primary.push(o) : others.push(o)));

    return [...primary, ...others];
  }

  return options;
};

const LandingChoices = () => {
  const { foreground } = useVendorColors();
  const isInverted = foreground === '#000000';
  const classes = useStyles({ theme: useTheme(), inverted: isInverted });
  const intl = useIntl();

  const history = useHistory();
  const device = React.useContext(ViewModeContext);
  const [locale] = React.useContext(LocaleContext);
  const features = React.useContext(FeatureContext);
  const [, , remapSteps, resetInitialSteps] = React.useContext(StepContext);
  const featureDecisions = React.useContext(FeatureDecisionContext);
  const [{ attendee, location, meetingMethod, service }, setSelections] =
    React.useContext(SelectionContext);
  const [state, setState] = React.useState(() => ({
    loading: true,
    queueable: true,
  }));
  const { appointmentBookingChannel } = useContext(FeatureContext);

  const method = meetingMethod || MEETING_METHODS.AT_LOCATION;
  const language = `&lang=${locale}`;
  const search = `?meeting_method=${method}${language}`;
  const showService = service ? '.service' : '';
  const isTouchKiosk = mode.isKiosk() && Detective.touchscreen();

  const isPrivateLocation = location?.visibility === visibilities.PRIVATE;
  const isPhysicalLocation = location?.physical;

  const options = getOptions({
    history,
    location,
    queueable: state.queueable,
    remapSteps,
    search,
    service: service?.id,
    isTouchKiosk,
    featureDecisions,
    setSelections,
    showAppointmentOption: appointmentBookingChannel,
  });

  useEffect(() => {
    if (featureDecisions.shouldUseKioskEnhancements) {
      resetInitialSteps();
      const searchParams = new URLSearchParams(history.location.search);
      const shouldAvoidResetingToAppt = searchParams.has('walk_in');
      if (!shouldAvoidResetingToAppt) {
        setSelections({ bookingWalkIn: false });
      }
    }

    if (service?.id && location?.id) {
      Api.lobbyAvailability()
        .all({
          location: location.id,
          service: service.id,
          meetingMethod: method,
        })
        .then((res) => {
          setState({
            loading: false,
            queueable: res.available && features.lobby,
          });
        });
    } else {
      Api.locations()
        .queueable(location.id)
        .then((res) => {
          setState({
            loading: false,
            queueable: res.queueable && features.lobby,
          });
        });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.id]);

  function generateCxSnippetOption(option) {
    return option.id === 'callback' ? (
      <div style={{ width: '100%', flexGrow: 1 }}>
        <CxSnippet targetId="kiosk_additional_meeting_method" />
      </div>
    ) : null;
  }

  const privateLocationButton = () => {
    return isTouchKiosk ? (
      <button
        className={classNames(
          classes.kioskListItem,
          device !== MOBILE && classes.kioskListItemHalf,
          classes.privateLocationButton,
        )}
        key={`choose-another-location`}
        onClick={() => {
          window.location.assign(`/where`);
        }}
        type="button"
      >
        <div className={classes.kioskButtonWrapper}>
          <div className={classNames(classes.kioskIcon)}>
            <Search />
          </div>
          <div>
            <Typography
              classes={{
                root: classNames(classes.buttonTitle),
              }}
              component="div"
              variant="title"
            >
              <FormattedMessage id="LandingChoices.private_location" />
            </Typography>
            <Typography
              classes={{
                root: classNames(classes.buttonSubtitle),
              }}
              component="div"
              variant="title"
            >
              <FormattedMessage id="LandingChoices.choose_another_location_subtitle" />
            </Typography>
          </div>
        </div>
      </button>
    ) : (
      <div className={classes.row}>
        <List>
          <Card
            action={() => {
              window.location.assign(`/where`);
            }}
            frontAdornment={
              <div className={classes.adornment}>
                <KioskSearch />
              </div>
            }
            icon
            iconMargin={false}
            key={`choose-another-location`}
            primary={<FormattedMessage id="LandingChoices.private_location" />}
            secondary={
              <FormattedMessage id="LandingChoices.choose_another_location_subtitle" />
            }
          />
        </List>
      </div>
    );
  };

  return (
    <>
      <TrackPageView identifier={PAGES.HOW} />

      {isTouchKiosk ? (
        <LandingKiosk>
          {state.loading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.kioskWrapper}>
              <div>
                <h1 className={classes.kioskTitle}>
                  <FormattedMessage id="LandingChoices.welcome_to" />
                  <br />
                  {location?.name}
                </h1>
                <Typography
                  classes={{
                    root: classes.kioskSubtitle,
                  }}
                  component="div"
                  variant="subtitle"
                  white={!isInverted}
                >
                  {isPhysicalLocation
                    ? location.formattedAddress
                    : intl.formatMessage({
                        id: 'LandingChoices.virtual_location',
                      })}
                </Typography>
                {isPrivateLocation ? privateLocationButton() : null}
              </div>

              {!isPrivateLocation && (
                <>
                  <div className={classes.kioskDivider} />

                  <div className={classes.kioskList}>
                    {options.map((option, i) => (
                      <>
                        <button
                          className={classNames(
                            classes.kioskListItem,
                            device !== MOBILE &&
                              i !== 0 &&
                              classes.kioskListItemHalf,
                            option.isPrimaryAction && classes.buttonPrimary,
                          )}
                          key={`${option.primary}|${option.secondary}`}
                          onClick={option.action}
                          type="button"
                        >
                          <div className={classes.kioskButtonWrapper}>
                            <div
                              className={classNames(
                                classes.kioskIcon,
                                option.isPrimaryAction &&
                                  classes.kioskIconPrimary,
                              )}
                            >
                              {option.icon}
                            </div>
                            <div>
                              <Typography
                                classes={{
                                  root: classNames(
                                    classes.buttonTitle,
                                    option.isPrimaryAction &&
                                      classes.buttonTitlePrimary,
                                  ),
                                }}
                                component="div"
                                variant="title"
                              >
                                <FormattedMessage id={option.primary} />
                              </Typography>
                              <Typography
                                classes={{
                                  root: classNames(
                                    classes.buttonSubtitle,
                                    option.isPrimaryAction &&
                                      classes.buttonSubtitlePrimary,
                                  ),
                                }}
                                component="div"
                                variant="title"
                              >
                                <FormattedMessage id={option.secondary} />
                              </Typography>
                            </div>
                          </div>
                        </button>
                        {generateCxSnippetOption(option)}
                      </>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </LandingKiosk>
      ) : (
        <Landing>
          {state.loading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Alert
                classes={{ secondaryText: classes.address }}
                icon={
                  <div className={classes.icon}>
                    <LocationPin />
                  </div>
                }
                message={location.name}
                secondary={
                  isPhysicalLocation
                    ? location.formattedAddress
                    : intl.formatMessage({
                        id: 'LandingChoices.virtual_location',
                      })
                }
                variant="info"
              />
              {isPrivateLocation ? privateLocationButton() : null}

              {!isPrivateLocation && (
                <>
                  <div className={classes.row}>
                    <Typography component="h1" variant="h5">
                      {/* the entity "&nbsp;" prevents "to do" from wrapping as a seperate words */}
                      <FormattedMessage
                        id={
                          attendee?.firstName
                            ? `LandingChoices.prefill_title${showService}`
                            : `LandingChoices.title${showService}`
                        }
                        values={{
                          name: attendee?.firstName,
                          service: service?.name?.toString().toLowerCase(),
                          space: <span>&nbsp;</span>,
                        }}
                      />
                    </Typography>
                  </div>
                  <div className={classes.row}>
                    <List>
                      {options.map((option) => (
                        <>
                          <Card
                            action={option.action}
                            frontAdornment={
                              <div className={classes.adornment}>
                                {option.icon}
                              </div>
                            }
                            icon
                            iconMargin={false}
                            id={option.id}
                            key={option.id}
                            primary={<FormattedMessage id={option.primary} />}
                            secondary={
                              <FormattedMessage id={option.secondary} />
                            }
                          />
                          {generateCxSnippetOption(option)}
                        </>
                      ))}
                    </List>
                  </div>
                </>
              )}
            </>
          )}
        </Landing>
      )}
    </>
  );
};

export default LandingChoices;
