import {
  Answer,
  Attendee,
  Notifications,
  Origins,
  Client,
} from 'coconut-open-api-js';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { DIGITAL_BANKING_STRINGS } from '../../shared/constants';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import QueueAppointmentsApi from '../../shared/helpers/api/open/QueueAppointments';
import Dates from '../../shared/helpers/Dates';
import mode from '../../shared/helpers/Mode';
import Reporter from '../../shared/helpers/Reporter';
import TagManager from '../../shared/helpers/TagManager';
import useDateTime from '../../shared/hooks/useDateTime';
import BookingValidationErrorModal from '../components/BookingValidationErrorModal';
import {
  STEPS,
  ORIGINS,
  APPOINTMENT_STATUSES,
  QUESTION_TYPES,
  MEETING_METHODS,
} from '../constants';
import Open from '../helpers/api/Open';
import Item from '../helpers/Item';
import Limits from '../helpers/Limits';
import Meta from '../helpers/Meta';
import ReserveWithGoogleConversion from '../helpers/ReserveWithGoogleConversion';
import Resources from '../helpers/Resources';
import { HTTP_CONFLICT, HTTP_UNPROCESSABLE_ENTITY } from '../helpers/Response';
import Shortcuts from '../helpers/Shortcuts';
import Steps from '../helpers/Step';
import Tracker from '../helpers/Tracker';
import Url from '../helpers/Url';
import UtmParameters from '../helpers/UtmParameters';
import { AppointmentContext } from './AppointmentContext';
import { FeatureContext } from './FeatureContext';
import { LocaleContext } from './LocaleContext';
import { SelectionContext } from './SelectionContext';
import { StepContext } from './StepContext';
import { TimezoneContext } from './TimezoneContext';
import { UsersContext } from './UsersContext';

const BookingContext = createContext({});

const makeAnswers = (questions) =>
  Item.map(questions, (question) => {
    let value = questions[question];

    // assumes that object means it is a checkbox group
    if (value.type !== QUESTION_TYPES.UPLOAD && typeof value === 'object') {
      const newValue = [];

      Item.map(value, (index) => {
        const checked = value[index];

        if (checked) {
          newValue.push(index);
        }
      });

      if (newValue.length) {
        value = newValue;
      } else {
        // nothing was answered for this question; shouldn't have any value
        delete questions[question];
      }
    }

    if (value.type === QUESTION_TYPES.UPLOAD) {
      value = { add: value.files };
    }

    return value ? new Answer().for(question).is(value) : null;
  }).filter(Boolean);

const BookingProvider = ({ children, goTo }) => {
  const history = useHistory();
  const { search } = useLocation();
  const {
    formatters: { formatDateTimeISO },
  } = useDateTime();
  const [steps] = useContext(StepContext);
  const [locale] = useContext(LocaleContext);
  const { spokenLanguages } = useContext(FeatureContext);
  const { shouldUseKioskEnhancements } = useContext(FeatureDecisionContext);
  const [{ userPreference }] = useContext(SelectionContext);
  const [timezone] = useContext(TimezoneContext);
  const [, setAppointment] = useContext(AppointmentContext);
  const [selections, setSelections] = useContext(SelectionContext);
  const { supportedLanguages } = useContext(UsersContext);
  const [state, setState] = useState({
    smsError: null,
    error: null,
    open: false,
    step: null,
    submitting: false,
  });

  const handleClose = () =>
    setState((prevState) => ({ ...prevState, open: false }));
  const handleGoToStep = () => {
    const queryParams = Url.params(search);

    switch (state.step) {
      case STEPS.SERVICE:
        if (!queryParams.service) {
          setSelections({ service: null });
        }
        break;
      case STEPS.LOCATION:
        if (!queryParams.location) {
          setSelections({ location: null });
        }
        break;
      case STEPS.TIMES:
        if (!queryParams.staff) {
          setSelections({ date: null, user: null });
        } else {
          setSelections({ date: null });
        }
        break;
      default:
        break;
    }

    goTo(
      steps.findIndex((step) => step.id === state.step),
      true,
    );
  };

  const makeAttendee = (attributes) => {
    const attendee = new Attendee()
      .alias(attributes.externalId || null)
      .located({
        address: attributes.address,
        city: attributes.city,
        country: attributes.country,
        postcode: attributes.postalCode,
        region: attributes.province,
        timezone,
      })
      .answers(makeAnswers(attributes.questions))
      .named(attributes.firstName, attributes.lastName)
      .provided(selections.attendee.notes)
      .messagable(attributes.receiveSms)
      .reachable({
        cell_phone: attributes.cellPhone,
        email: attributes.email,
        phone: attributes.homePhone,
        work_phone: attributes.workPhone,
      });

    if (spokenLanguages) {
      attendee.speaks(`${locale},${userPreference?.id}`);
    } else {
      attendee.speaks(locale);
    }

    return attendee;
  };

  const submitBooking = () => {
    const preference = selections.userPreference?.id;
    const allAttendees = [selections.attendee, ...selections.attendees];
    const modeParam = Url.params(history.location.search)?.mode;
    const useNewKiosk = Url.params(history.location.search)?.use_new_kiosk;
    const walkInToConvert = Url.params(history.location.search)?.queue_appt_id;
    const walkInConfirmation = Url.params(
      history.location.search,
    )?.queue_confirm_code;
    const workflow = Url.params(history.location.search)?.workflow;
    const integrationPartner = Shortcuts.get('partner');
    const origin = (() => {
      if (modeParam) {
        return ORIGINS[modeParam.toUpperCase()];
      }

      if (
        window.state.reserve_with_google.rwg_token &&
        window.state.reserve_with_google.location_id
      ) {
        return ORIGINS.RESERVE_WITH_GOOGLE;
      }

      return Origins.MODERN_CLIENT_VIEW;
    })();

    setState((prevState) => ({ ...prevState, submitting: true }));

    if (selections.bookingWalkIn) {
      const client = new Client()
        .alias(allAttendees[0].externalId || null)
        .answers(makeAnswers(allAttendees[0].questions))
        .messagable(selections.attendee.receiveSms)
        .provided(selections.attendee.notes)
        .named(selections.attendee.firstName, selections.attendee.lastName)
        .reachable({
          cell_phone: selections.attendee.cellPhone,
          email: selections.attendee.email,
        });

      const bookingThrough = () => {
        if (window.state.reserve_with_google.rwg_token) {
          return ORIGINS.RESERVE_WITH_GOOGLE;
        }

        if (mode.isBookingThroughKioskQR()) {
          return ORIGINS.KIOSK_QR;
        }

        return origin;
      };

      Open.api()
        .locale(locale)
        .queueAppointments()
        .at(selections.location.id)
        .for(selections.service.id)
        .with(client)
        .provided(selections.attendee.notes)
        .method(selections.meetingMethod || MEETING_METHODS.AT_LOCATION)
        .campaign(UtmParameters.get('utm_campaign'))
        .content(UtmParameters.get('utm_content'))
        .medium(UtmParameters.get('utm_medium'))
        .source(UtmParameters.get('utm_source'))
        .term(UtmParameters.get('utm_term'))
        .through(bookingThrough())
        .workflow(workflow)
        .book()
        .then((response) => {
          TagManager.trackEngagement(
            null,
            TagManager.engagements.BOOK_QUEUE_APPOINTMENT,
            'successful',
          );

          ReserveWithGoogleConversion.handleConversion(selections.location.id);

          const confirmCode = `code=${response?.data?.data?.attributes?.confirm_code}`;
          const id = `&id=${response?.data?.data?.id}`;
          const lang = `&lang=${locale || 'en'}`;
          const kiosk = mode.checkAndGetKioskParam();
          let url = `/callback-service/confirmation?${confirmCode}${id}${kiosk}${lang}`;

          if (shouldUseKioskEnhancements && useNewKiosk) {
            url += '&use_new_kiosk';
            url += mode.getBookedThroughKioskQRParam();
          }

          window.location.assign(url);
        })
        .catch(({ response: { data, status } }) => {
          TagManager.trackEngagement(
            null,
            TagManager.engagements.BOOK_QUEUE_APPOINTMENT,
            'failed',
          );

          if (status === HTTP_UNPROCESSABLE_ENTITY) {
            const error = Item.first(data.errors);
            const { detail, source, title } = error;
            Reporter.exception(new Error(title));

            if (source) {
              let { pointer } = source;

              if (source.pointer.includes('/data/attributes/service_id')) {
                pointer = '/data/attributes/service_id';
              }

              const stepToGo = Steps.getStep(pointer);
              const options = {
                error: null,
                open: true,
                step: null,
                submitting: false,
                smsError: null,
              };

              if (source.pointer.includes('receive_sms')) {
                options.smsError = Item.first(detail);
              }

              if (Item.includes(STEPS, stepToGo)) {
                options.step = stepToGo;
              } else {
                options.error = Item.first(detail);
              }

              setState(options);

              return;
            }
          }
        });
    } else {
      const date = new Date(
        selections.date.year(),
        selections.date.month(),
        selections.date.date(),
        selections.date.hour(),
        selections.date.minute(),
        selections.date.second(),
      );

      const bookingThrough = () => {
        if (integrationPartner) {
          if (integrationPartner === DIGITAL_BANKING_STRINGS.BANNO) {
            return ORIGINS.BANNO;
          } else {
            return ORIGINS.DIGITAL_BANKING_PLATFORM;
          }
        }

        if (mode.isBookingThroughKioskQR()) {
          return ORIGINS.KIOSK_QR;
        }

        return origin;
      };

      const request = Open.api()
        .locale(locale)
        .appointments()
        .at(selections.location.id)
        .when(Item.filled(selections.user || {}), (api) =>
          api.by(selections.user.id),
        )
        .when(selections.userCategory?.id && !selections.user, (api) =>
          api.withinUserCategory(selections.userCategory.id),
        )
        .when(selections.additionalUsers.length, (api) =>
          api.attendedBy(selections.additionalUsers.map(({ id }) => id)),
        )
        .for(selections.service.id)
        .supporting(
          supportedLanguages?.includes(preference) ? preference : null,
        )
        .in(timezone)
        .starting(formatDateTimeISO(date))
        .with(allAttendees.map(makeAttendee))
        .when(selections.uploads?.length > 0, (api) =>
          api.uploads(selections.uploads),
        )
        .notify(Notifications.ALL)
        .actingAs(Meta.booker())
        .when(selections.meetingMethod !== null, (api) =>
          api.method(selections.meetingMethod),
        )
        .when(Shortcuts.get('booking_shortcut_id') !== null, (api) =>
          api.shortcut(Shortcuts.get('booking_shortcut_id')),
        )
        .withInviteOnly(
          selections.booking_shortcut_id !== null &&
            selections.shortcuts?.settings?.invite_only_resources === true,
        )
        .campaign(UtmParameters.get('utm_campaign'))
        .content(UtmParameters.get('utm_content'))
        .medium(UtmParameters.get('utm_medium'))
        .source(UtmParameters.get('utm_source'))
        .term(UtmParameters.get('utm_term'))
        .through(bookingThrough())
        .workflow(workflow)
        .recaptcha(selections.attendee.recaptchaToken);

      const params = [];
      if (mode.isKiosk()) {
        params.push(mode.kioskParam());
      }
      if (mode.isEmbedded()) {
        params.push(mode.embeddedParam());
      }
      if (shouldUseKioskEnhancements && useNewKiosk) {
        params.push('use_new_kiosk');
        params.push(mode.getBookedThroughKioskQRParam(''));
      }

      (selections.appointment
        ? request.add(selections.appointment)
        : request.book()
      )
        .then(({ data: { data, included } }) => {
          const includedUser = [...Item.included(included, 'users')];
          const includedLocation = Item.first(
            Item.included(included, 'locations'),
          );
          const includedService = Item.first(
            Item.included(included, 'services'),
          );
          const includedAttendees = Item.included(included, 'attendees');
          const appointment = Item.first(data);
          const newestAttendee = includedAttendees.reduce(
            (prev, current) => (current.id > prev.id ? current : prev),
            includedAttendees[0],
          );
          const primaryAttendee = includedAttendees.reduce(
            (prev, current) => (current.id < prev.id ? current : prev),
            includedAttendees[0],
          );
          const isGroup = includedService?.attributes?.group || false;
          const attendeeToUpdate = isGroup ? newestAttendee : primaryAttendee;
          let newSelections = {
            attendee: {
              ...selections.attendee,
              id: attendeeToUpdate.id,
            },
          };

          if (includedUser.length) {
            selections.user = Resources.formatUser(
              includedUser[includedUser.length - 1],
            );
          }

          Limits.clear();

          setAppointment({
            id: appointment.id,
            confirmCode: attendeeToUpdate.attributes.confirm_code,
            end: Dates.parse(appointment.attributes.client_end, timezone),
            endRaw: appointment.attributes.client_end,
            reschedulable: appointment.attributes.reschedulable,
            startRaw: appointment.attributes.start,
            status: appointment.attributes.status,
            location: includedLocation.id,
            meetingLink: appointment.attributes.meeting_link,
            dialIn: appointment.attributes.dial_in,
            bookAnotherLink: appointment.attributes.book_another_link,
          });

          newSelections = {
            ...newSelections,
            location: Resources.formatLocation(includedLocation),
          };

          setSelections(newSelections);

          ReserveWithGoogleConversion.handleConversion(selections.location.id);

          if (Tracker.canSubmitConversion()) {
            Tracker.submitConversion();
          }

          // Cancel previously booked walk-in if params are present after new appointment booked
          if (walkInToConvert && walkInConfirmation) {
            QueueAppointmentsApi.patch(walkInToConvert, {
              status: APPOINTMENT_STATUSES.CANCELLED,
              confirm_code: walkInConfirmation,
            }).catch(({ response: { data } }) => {
              const error = Item.first(data.errors);
              const { title } = error;
              Reporter.exception(new Error(title));
            });
          }

          history.push(
            `/confirmation${params.length ? `?${params.join('&')}` : ''}`,
          );
        })
        .catch(({ response: { data, status } }) => {
          if (status === HTTP_UNPROCESSABLE_ENTITY) {
            const error = Item.first(data.errors);
            const { detail, source, title } = error;
            Reporter.exception(new Error(title));

            if (source) {
              let { pointer } = source;

              if (source.pointer.includes('/data/attributes/service_id')) {
                pointer = '/data/attributes/service_id';
              }

              const stepToGo = Steps.getStep(pointer);
              const options = {
                error: null,
                open: true,
                step: null,
                submitting: false,
                smsError: null,
              };

              if (source.pointer.includes('receive_sms')) {
                options.smsError = Item.first(detail);
              }

              if (Item.includes(STEPS, stepToGo)) {
                options.step = stepToGo;
              } else {
                options.error = Item.first(detail);
              }

              setState(options);

              return;
            }
          }

          if (status === HTTP_CONFLICT) {
            const error = Item.first(data.errors);

            setState({
              error: error.detail,
              open: true,
              step: null,
              submitting: false,
              smsError: null,
            });

            return;
          }

          history.push(
            `/confirmation${params.length ? `?${params.join('&')}` : ''}`,
          );
        });
    }
  };

  return (
    <BookingContext.Provider
      value={{
        smsError: state.smsError,
        submitting: state.submitting,
        submitBooking,
      }}
    >
      {children}
      <BookingValidationErrorModal
        errorMessage={state.error}
        handleClose={handleClose}
        open={state.open}
        step={state.step ? { title: state.step, action: handleGoToStep } : null}
      />
    </BookingContext.Provider>
  );
};

BookingProvider.propTypes = {
  children: PropTypes.element.isRequired,
  goTo: PropTypes.func.isRequired,
};

export { BookingContext, BookingProvider };
