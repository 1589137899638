import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  root: {
    color: theme.palette.black,
    fontWeight: theme.fontWeights.normal,
  },
  h5: {
    fontSize: theme.textSizes.xl,
    fontWeight: theme.fontWeights.medium,
    lineHeight: theme.leading.xl,
  },
  h6: {
    fontSize: theme.textSizes.lg,
    fontWeight: theme.fontWeights.medium,
    lineHeight: theme.leading.lg,
  },
  title: {
    color: theme.palette.black,
    fontSize: theme.textSizes.base,
    fontWeight: theme.fontWeights.medium,
    lineHeight: theme.leading.base,
  },
  regular: {
    fontSize: theme.textSizes.base,
    fontWeight: theme.fontWeights.normal,
    lineHeight: theme.leading.base,
  },
  subtitle: {
    color: theme.palette.black,
    fontSize: theme.textSizes.sm,
    lineHeight: theme.leading.sm,
  },
  label: {
    color: theme.palette.black,
    fontSize: theme.textSizes.sm,
    fontWeight: theme.fontWeights.medium,
    lineHeight: theme.leading.sm,
  },
  caption1: {
    color: theme.palette.neutral[400],
    fontSize: theme.textSizes.sm,
    lineHeight: theme.leading.sm,
  },
  caption1_inherit: {
    color: 'inherit',
    fontSize: theme.textSizes.sm,
    lineHeight: theme.leading.sm,
  },
  caption2: {
    color: theme.palette.neutral[300],
    fontSize: theme.textSizes.sm,
    lineHeight: theme.leading.sm,
  },
  button: {
    color: 'inherit',
    textTransform: 'uppercase',
    fontSize: theme.textSizes.sm,
    lineHeight: theme.leading.sm,
    fontWeight: theme.fontWeights.medium,
  },
  body1: {
    color: theme.palette.neutral[400],
    fontSize: theme.textSizes.xs,
    lineHeight: theme.leading.xs,
  },
  body2: {
    fontSize: theme.textSizes.xs,
    lineHeight: theme.leading.xs,
  },
  error: {
    color: theme.palette.danger[400],
    fontSize: theme.textSizes.xs,
    lineHeight: theme.leading.xs,
  },
  help: {
    color: theme.palette.neutral[400],
    fontSize: theme.textSizes.xs,
    lineHeight: theme.leading.xs,
  },
  small: {
    color: theme.palette.neutral[400],
    fontSize: theme.textSizes.xxs,
    lineHeight: theme.leading.xxs,
  },
  primary: {
    color: theme.palette.secondary[400],
  },
  dark: {
    color: theme.palette.black,
  },
  grey: {
    color: theme.palette.neutral[400],
  },
  white: {
    color: theme.palette.white,
  },
}));

// Temporary workaround to be free of type errors from .tsx files using this component
/**
 * @type {React.FC<React.PropsWithChildren<{
 *   classes?: any
 *   component?: string,
 *   dark?: boolean,
 *   grey?: boolean,
 *   onClick?: () => void,
 *   primary?: boolean,
 *   variant?: string
 *   white?: boolean,
 * }>>}
 */
const Typography = ({
  children,
  classes: injectedClasses,
  component: Name,
  dark,
  grey,
  onClick,
  primary,
  variant,
  white,
  ...props
}) => {
  const classes = {
    ...useStyles({ theme: useTheme() }),
    ...injectedClasses,
  };
  const { ...properties } = props;

  return (
    <Name
      className={classNames(
        classes.root,
        classes[variant],
        primary && classes.primary,
        dark && classes.dark,
        grey && classes.grey,
        white && classes.white,
      )}
      onClick={onClick}
      {...properties}
    >
      {children}
    </Name>
  );
};

Typography.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
  component: PropTypes.string,
  dark: PropTypes.bool,
  grey: PropTypes.bool,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  variant: PropTypes.oneOf([
    'h5',
    'h6',
    'title',
    'regular',
    'subtitle',
    'label',
    'caption1',
    'caption1_inherit',
    'caption2',
    'button',
    'body1',
    'body2',
    'error',
    'help',
    'small',
  ]),
  white: PropTypes.bool,
};

Typography.defaultProps = {
  classes: {},
  component: 'span',
  dark: false,
  grey: false,
  onClick: null,
  primary: false,
  variant: 'body1',
  white: false,
};

export default Typography;
