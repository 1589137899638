import Url from '../../frontend/helpers/Url';
import {
  SELECTION_STORAGE_KEY,
  LANGUAGE_STORAGE_KEY,
  FORMDATA_STORAGE_KEY,
} from '../constants';
import Reporter from './Reporter';
import Storage from './Storage';

const KIOSK_PARAM = 'mode=kiosk';
const TV_PARAM = 'mode=tv';
const LOCATION_PARAM = 'location=e:';
const INVITATION_PARAM = 'mode=invitation';
const EMBEDDED_PARAM = 'mode=embed';
const KIOSK_QR_PARAM = 'booked_through=kiosk_qr';

const RESET_ROUTE_EXCEPTIONS = ['/how', '/where'];

export default {
  isEmbedded() {
    return window.location.search.includes(EMBEDDED_PARAM);
  },
  isKiosk() {
    return window.location.search.includes(KIOSK_PARAM);
  },
  isBookingThroughKioskQR() {
    return window.location.search.includes(KIOSK_QR_PARAM);
  },
  getBookedThroughKioskQRParam(prefix = '&') {
    return this.isBookingThroughKioskQR() ? `${prefix}${KIOSK_QR_PARAM}` : '';
  },
  isTv() {
    return window.location.search.includes(TV_PARAM);
  },
  isInvitation() {
    return window.location.search.includes(INVITATION_PARAM);
  },
  handleKioskConfirmation(
    selections,
    resetSelections,
    clearStorage = true,
    locale = false,
  ) {
    if (this.isKiosk()) {
      const reset = () =>
        this.resetKiosk(selections, resetSelections, clearStorage, locale);
      setTimeout(reset, 8500);
    }
  },
  checkAndGetKioskParam() {
    if (this.isKiosk()) {
      return `&${this.kioskParam()}`;
    }

    return '';
  },
  embeddedParam() {
    return EMBEDDED_PARAM;
  },
  kioskParam() {
    return KIOSK_PARAM;
  },
  tvParam() {
    return TV_PARAM;
  },
  resetKiosk(selections, resetSelections, clearStorage = true, locale = false) {
    const isResettableRoute =
      window.location.href &&
      !RESET_ROUTE_EXCEPTIONS.some((rre) => window.location.href.includes(rre));

    if (isResettableRoute) {
      const storeItems =
        selections && Object.keys(selections).length
          ? selections
          : Storage.get(SELECTION_STORAGE_KEY) || {};

      const customRedirectUrl = window.state?.meta?.kiosk_redirect_url;
      const externalId =
        storeItems?.location?.external_id || window.state.location?.external_id;

      if (clearStorage) {
        Storage.clear(SELECTION_STORAGE_KEY);
        Storage.clear(FORMDATA_STORAGE_KEY);
      }
      if (resetSelections) {
        resetSelections();
      }
      if (!storeItems.location) {
        storeItems.location = window.state.location;
      }
      if (!storeItems.location) {
        const parts = window.location.pathname.split('/');
        const locationId = parts[2];
        storeItems.location = { id: locationId };
      }

      // TEMP logging for CORE-13182. can be removed when bug is found.
      if (!storeItems?.location?.id) {
        Reporter.exception(
          new Error('No location ID in reset context', {
            windowObj: window,
            storeItems,
          }),
        );
      }

      let localeParam = '';
      if (locale) {
        Storage.clear(LANGUAGE_STORAGE_KEY);
        localeParam = `&lang=${locale}`;
      }

      let defaultUrl = `/how?location=${storeItems.location.id}&${KIOSK_PARAM}${localeParam}`;

      if (Url.params(window.location.search).use_new_kiosk) {
        defaultUrl += `&use_new_kiosk`;
        defaultUrl += this.getBookedThroughKioskQRParam();
      }

      const kioskUrl = customRedirectUrl
        ? `${customRedirectUrl}?${LOCATION_PARAM}${externalId}${localeParam}`
        : defaultUrl;

      window.location.assign(kioskUrl);
    }
  },
};
